const { uniqBy, pick, groupBy, keyBy, sortBy, omit, } = require('lodash');

const { accountItemCategories } = require('../config');
const { getCategory, } = require('../models/accountItem');
const { noneSection, } = require('../models/section');
const { uniqAccountName, uniqSectionName, } = require('../util');
const { generalSegment, } = require('../models/consolidationSegment');

const generateRows = (relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems) => {
  const allSections = [...sections, noneSection(relatedCompany, company)];
  const allConsolidationSegments = [...consolidationSegments, generalSegment];
  const consolidationSegmentsById = keyBy(allConsolidationSegments, 'id');
  const sectionsByName = keyBy(allSections, _ => uniqSectionName(relatedCompany, _.name, _.code));
  const accountItemsByName = keyBy(accountItems, _ => uniqAccountName(relatedCompany, _.name, _.shortcut_num));
  const consolidationAccountItemsById = keyBy(consolidationAccountItems, 'id');
  const rows = sectionTrials.map((sectionTrial) => {
    const { account_item_name: accountItemName, account_category_name: accountCategoryName, key, } = sectionTrial;
    const accountItem = accountItemsByName[uniqAccountName(relatedCompany, accountItemName, key)];
    const consolidationAccountItem = consolidationAccountItemsById[accountItem?.consolidationAccountItemId];
    const sections = sectionTrial.sections.map((row) => {
      const section = sectionsByName[uniqSectionName(relatedCompany, row.name, row.id)];
      const consolidationSegment = consolidationSegmentsById[section?.consolidationSegmentId];
      return {
        ...row,
        section,
        consolidationSegment,
      };
    });
    return {
      ...sectionTrial,
      accountItem,
      consolidationAccountItem,
      category: getCategory(accountItem),
      sections,
    };
  });
  const rowsGroupedByCategory = groupBy(rows, 'category.name');
  const sortedRows = accountItemCategories.flatMap((category) => {
    return sortBy(rowsGroupedByCategory[category.name], _ => _.accountItem?.index);
  });
  return sortedRows;
};

const rowsForExport = (relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems) => () => {
  return generateRows(relatedCompany, company, sections, consolidationSegments, sectionTrials, accountItems, consolidationAccountItems).flatMap((sectionTrial) => {
    const { sections = [], } = sectionTrial;
    return sections.map((row) => {
      const { name, closing_balance: closingBalance, consolidationSegment, } = row;
      return {
        ...pick(sectionTrial, ['account_category_name', 'account_item_name']),
        account_code: sectionTrial.accountItem?.shortcut_num,
        sectionName: row.name,
        consolidationSegmentName: consolidationSegment?.name,
        ...pick(row, ['closing_balance',]),
      };
    });
  });
};

const computeAlerts = (...args) => {
  const rows = generateRows(...args);
  const noConsolidationSegmentSections = uniqBy(rows.flatMap(_ => _.sections).filter(_ => _.consolidationSegment == null), 'section.id');
  return [
    noConsolidationSegmentSections.length > 0 && `連結セグメントがマッピングされていない部門があります(${noConsolidationSegmentSections.map(_ => _.name).join(', ')})`,
  ].filter(_ => _);
};

exports.generateRows = generateRows;
exports.rowsForExport = rowsForExport;
exports.computeAlerts = computeAlerts;
