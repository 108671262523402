import React, { Component, useEffect, Fragment, useState, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import 'lodash.multicombinations';
import { isEmpty, orderBy, uniq, multicombinations, sum, mapValues, zip, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, } from 'papaparse';
import JSZip from 'jszip';
import { endOfMonth, parse as parseDate, format as formatDate } from 'date-fns';
import dedent from 'dedent';

import firebase, { functions } from '../../firebase';
import { integerFormat } from '../../util';
import { prevYearMonth, } from '../../shared/util';
import { getDocumentData, getCollectionData, } from '../../shared/firebase';
import { createOperationLog, fiscalYearOfPeriod, } from '../../utils';
import { accountItemCategories, accountItemCategoryNames, cfAccountItemCategories, wizlaboDocuments, } from '../../shared/config';
import { generalSettingsFields, } from '../../shared/models/company';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import HelpLink from '../HelpLink';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import AddButton from '../AddButton';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import OperationHistoryButton from '../OperationHistoryButton';
import QuerySelector from '../QuerySelector';
import ModelForm from '../forms/ModelForm';

const { keys, entries } = Object;
const { abs } = Math;
const cfAccountItemCategoryKeys = keys(cfAccountItemCategories);
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const sendToWizlabo = functions.httpsCallable('sendToWizlabo', { timeout: 550000 });
const sendToPronexus = functions.httpsCallable('sendToPronexus', { timeout: 550000 });
const fetchPronexusFiscalPeriods = functions.httpsCallable('fetchPronexusFiscalPeriods', { timeout: 550000 });
const wizlaboFields = {
  documents: {
    type: 'multiSelect',
    label: '送信する帳票',
    options: entries(wizlaboDocuments).map(([k, v]) => ({ label: v.label, value: k })),
    validations: {
      required: _ => !isEmpty(_),
    },
  },
};

export default CompanyPage(function CompanyIntegration (props) {
  const { user, company, history, location, prevEndYearMonth, isLockedMonth, } = props;
  const { period, yearMonth, } = useQueryParams();
  const prevMonth = prevYearMonth(yearMonth);
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'));
  const relatedCompanies = [company, ...subsidiaries];
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const onSubmitWizlabo = async (values) => {
    const startDate = fiscalYearOfPeriod(period, company.fiscalYears)?.start_date.replace(/-/g, '');
    const endDate = formatDate(endOfMonth(parseDate(yearMonth + '01', 'yyyyMMdd', new Date())), 'yyyyMMdd');
    try {
      await sendToWizlabo({ companyId: company.id, startDate, endDate, prevEndYearMonth, period, ...values, });
      await createOperationLog(company, '', 'sendToWizlabo', user, { period, yearMonth, });
      toast.success('連携しました');
    } catch(e) {
      console.log({ e });
      toast.error(dedent`
        連携に失敗しました。

        ${e.message}
      `);
    }
  };

  return props.translate(
    <div className="company-account-item-mappings container">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-center mb-3">
          <h4>開示連携</h4>
        </div>
        {
          company.usesMonthlyAr && <div className="mt-3 alert alert-danger">当機能は「{generalSettingsFields(company).usesMonthlyAr.label}」にまだ対応していません</div>
        }
        <div className="mb-3 d-flex justify-content-end align-items-end gap-1">
          {
            company.usesPronexus && (
              <div className="d-flex flex-column align-items-end">
                <OperationHistoryButton type="sendToPronexus" />
                <ModalButton color="primary" disabled={isLockedMonth} title="PRONEXUS WORKS" content={_ => <PronexusModalContent {...{ user, company, prevEndYearMonth, period, }} />}>
                  WORKS-iに送信
                </ModalButton>
              </div>
            )
          }
          {
            company.usesWizLabo && (
              <div className="d-flex flex-column align-items-end">
                <OperationHistoryButton type="sendToWizlabo" />
                <ModalButton color="primary" disabled={isLockedMonth} Modal={ModelFormModal} modalProps={{ title: 'WizLaboに送信', fields: wizlaboFields, submitLabel: '送信する', onSubmit: onSubmitWizlabo, }}>
                  WizLaboに送信
                </ModalButton>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

function PronexusModalContent (props) {
  const { user, company, prevEndYearMonth, period, } = props;
  const { yearMonth, } = useQueryParams();
  const { value: fiscalPeriods, loading: isLoading, error, } = useAsync(async () => {
    const { data: { FiscalPeriods: fiscalPeriods } } = await fetchPronexusFiscalPeriods({ companyId: company.id });
    return fiscalPeriods;
  }, []);
  const fiscalPeriodsByDisplayText = keyBy(fiscalPeriods, 'DisplayText');
  const fields = {
    fiscalPeriod: {
      type: 'select',
      label: 'PRONEXUS WORKS 会計期間',
      options: (fiscalPeriods || []).map(_ => ({ label: _.DisplayText, value: _.DisplayText })),
    },
  };
  const onSubmit = async (values) => {
    const fiscalPeriod = fiscalPeriodsByDisplayText[values.fiscalPeriod];
    try {
      await sendToPronexus({ companyId: company.id, fiscalPeriod, prevEndYearMonth, period, });
      await createOperationLog(company, '', 'sendToPronexus', user, { period, yearMonth, });
      toast.success('連携しました');
    } catch(e) {
      console.log({ e });
      toast.error(dedent`
        連携に失敗しました。

        ${e.message}
      `);
    }
  };

  return (
    <div>
      {
        isLoading ? (
          <span className="fas fa-spin fa-spinner" />
        ) : (
          fiscalPeriods && <ModelForm fields={fields} submitLabel="送信" onSubmit={onSubmit} />
        )
      }
      {
        error != null && (
          <div className="alert alert-danger">
            {error.message}
          </div>
        )
      }
    </div>
  );
}
