import React, { Fragment, useEffect, useState, } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { keyBy, pick, isEmpty, omit, pickBy, groupBy, } from 'lodash';
import { useSessionStorage, useToggle, useAsync, } from 'react-use';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { fieldsToAdd as fields, pcaCompanyFields, } from '../../shared/models/company';
import { pcaIntegrationFields, } from '../../shared/models/subsidiary';
import { pcaDataCenters, } from '../../shared/config';
import HelpLink from '../HelpLink';
import HeaderNav from '../HeaderNav';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import FreeeCompaniesModal from '../modals/FreeeCompaniesModal';
import OverlayLoading from '../OverlayLoading';
import ModelFormModal from '../modals/ModelFormModal';
import NonFreeeCompanyFormModal from '../modals/NonFreeeCompanyFormModal';
import AddButton from '../AddButton';
import ProgressButton from '../ProgressButton';
import ModalButton from '../ModalButton';
import env from '../../env';
import useLocale from '../hooks/useLocale';

const getFreeeAuthUrl = functions.httpsCallable('getFreeeAuthUrl');
const getFreeeCompanies = functions.httpsCallable('getFreeeCompanies');
const getBugyoAuthUrl = functions.httpsCallable('getBugyoAuthUrl');
const getPcaAuthUrl = functions.httpsCallable('getPcaAuthUrl');
const getMfAuthUrl = functions.httpsCallable('getMfAuthUrl');
const getMfCompanies = functions.httpsCallable('getMfCompanies');
const db = firebase.firestore();
const auth = firebase.auth();
const usersRef = db.collection('users');
const companiesRef = db.collection('companies');
const displaySubsidiariesLength = 3;
const { keys, entries, } = Object;

export default function Dashboard (props) {
  const { history, location, user, companies, } = props;
  const { locale, dict, translate } = useLocale();
  const queryParams = useQueryParams();
  const [bugyoCompanies, setBugyoCompanies] = useState(null);
  const [pcaDataAreas, setPcaDataAreas] = useState(null);
  const [bugyoIntegrationPath, setBugyoIntegrationPath] = useSessionStorage('bugyoIntegrationPath');
  const [pcaIntegrationPath, setPcaIntegrationPath] = useSessionStorage('pcaIntegrationPath');
  const [pcaDataCenterCode, setPcaDataCenterCode] = useSessionStorage('pcaDataCenterCode');
  const [pcaIsHyper, setPcaIsHyper] = useSessionStorage('pcaIsHyper');
  const [mfIntegrationPath, setMfIntegrationPath] = useSessionStorage('mfIntegrationPath');
  const subsidiaries = useCollectionsFetch(companies.map(_ => _.ref.collection('subsidiaries').orderBy('index')), [companies]);
  const subsidiariesGroupedByCompanyId = groupBy(subsidiaries, 'ref.parent.parent.id');
  const [isIntegratingWithFreee, toggleIntegratingWithFreee] = useToggle(false);
  const integrateWithFreee = async () => {
    toggleIntegratingWithFreee(true);
    const { data: url } = await getFreeeAuthUrl();
    window.location.href = url;
  }
  const separateFromFreee = async () => {
    if (!window.confirm('本当に連携を解除しますか？')) return;

    await user.ref.update({ freee: firebase.firestore.FieldValue.delete() })
    toast.success('freeeとの連携を解除しました');
  };
  const integrateWithBugyo = async () => {
    setBugyoIntegrationPath('/');
    const { data: url } = await getBugyoAuthUrl();
    window.location.href = url;
  }
  const integrateWithPca = async (values) => {
    setPcaIntegrationPath('/');
    setPcaDataCenterCode(values.dataCenterCode);
    setPcaIsHyper(values.isHyper);
    const { data: url } = await getPcaAuthUrl({ ...values, });
    window.location.href = url;
  }
  const separateFromBugyo = async () => {
    if (!window.confirm('本当に連携を解除しますか？')) return;

    await user.ref.update({ bugyo: firebase.firestore.FieldValue.delete() })
    toast.success('勘定奉行クラウドとの連携を解除しました');
  };
  const integrateWithMf = async () => {
    setMfIntegrationPath('/');
    const { data: url } = await getMfAuthUrl();
    window.location.href = url;
  }
  const { value: freeeCompanies = [] } = useAsync(async () => {
    if(user.freee == null) return;

    const { data: { freeeCompanies = [] } } = await getFreeeCompanies({ includesExisting: false });
    return freeeCompanies;
  }, [user.freee]);
  const { value: mfCompanies = [] } = useAsync(async () => {
    const { data: { mfCompanies = [] } } = await getMfCompanies({ includesExisting: false });
    return mfCompanies;
  }, [user.mf]);
  useEffect(() => {
    (async () => {
      if(queryParams.mf === '1') {
        if(mfIntegrationPath === '/') {
          if(queryParams.companyExists === '1') {
            toast.error('すでに追加されている事業所です');
            return;
          }

          const { office, accountingPeriods } = queryParams;
          await db.collection('companies').doc().set({
            display_name: office.office_name,
            createdAt: new Date(),
            users: {
              [user.id]: { role: 'owner', },
            },
            externalType: 'mf',
            fiscalYears: accountingPeriods.map(_ => omit(_, 'office_name')),
            mf: {
              ...office,
              ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
            },
          });
          toast.success('追加しました');
          history.replace('/');
        } else if(mfIntegrationPath?.startsWith('/companies')) {
          history.replace(mfIntegrationPath + window.location.search);
        }
      } 
      if(queryParams.bugyo === '1') {
        if(bugyoIntegrationPath === '/') {
          const { bugyoCompanies, } = queryParams;
          if(bugyoCompanies.every(_ => _.existsInYui === 'true')) {
            toast.error('すでに追加されている事業所しか見つかりませんでした');
          } else {
            setBugyoCompanies(bugyoCompanies);
          }
        } else if(bugyoIntegrationPath?.startsWith('/companies')) {
          history.replace(bugyoIntegrationPath + (bugyoIntegrationPath.includes('?') ? ('&' + window.location.search.slice(1)) : window.location.search));
        }
      } 
      if(queryParams.pca === '1') {
        if(pcaIntegrationPath === '/') {
          const { dataAreas, } = queryParams;
          setPcaDataAreas(dataAreas);
        } else if(pcaIntegrationPath?.startsWith('/companies')) {
          history.replace(pcaIntegrationPath + (pcaIntegrationPath.includes('?') ? ('&' + window.location.search.slice(1)) : window.location.search));
        }
      } 
    })();
  }, [location.search, user]);

  return props.translate(
    <div className="dashboard h-100">
      <HeaderNav {...props} />
      <div className="container">
        <div className="p-4 d-flex flex-column justify-content-center align-items-stretch">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="会計ソフトと連携する" />
          </div>
          <div className="d-flex justify-content-end gap-1">
            {
              user.freee && (
                <AddButton
                  itemRef={companiesRef.doc()}
                  processValues={(values) => {
                    const freeeCompany = freeeCompanies.find(_ => _.id === values.sourceId);
                    return ({
                      ...values,
                      ...omit(freeeCompany, ['id']),
                      createdAt: new Date(),
                      users: {
                        [user.id]: { role: 'owner', },
                      },
                      externalType: 'freee',
                    });
                  }}
                  FormModal={ModelFormModal}
                  formProps={{ title: '事業所 追加', fields: fields({ externalCompanies: freeeCompanies, }), }}
                  label="freeeから事業所(親会社)を追加"
                />
              )
            }
            {
              !user.freee && (
                <Button color="primary" onClick={integrateWithFreee} disabled={isIntegratingWithFreee}>
                  <span className={classnames('fas mr-1', { 'fa-link': !isIntegratingWithFreee, 'fa-spin fas-spinner': isIntegratingWithFreee })} />
                  freeeと連携する
                </Button>
              )
            }
            {
              user.freee && (
                <Button color="danger" onClick={separateFromFreee}>
                  <span className="fas fa-unlink mr-1" />freeeとの連携を解除する
                </Button>
              )
            }
          </div>
          <div className="mt-1 d-flex justify-content-end gap-1">
            <ProgressButton color="primary" process={integrateWithBugyo}>
              <span className={classnames('fas mr-1 fa-plus')} />
              勘定奉行クラウドから事業所(親会社)を追加
            </ProgressButton>
            <ModalButton
              color="primary"
              Modal={ModelFormModal}
              modalProps={{
                title: 'PCAクラウドから事業所(親会社)を追加',
                fields: pcaIntegrationFields({ pcaDataCenters }),
                submitLabel: '次へ',
                onSubmit: integrateWithPca,
              }}
            >
              <span className={classnames('fas mr-1 fa-plus')} />
              PCAクラウドから事業所(親会社)を追加
            </ModalButton>
          </div>
          <div className="mt-1 d-flex justify-content-end gap-1">
            <ProgressButton color="primary" process={integrateWithMf}>
              <span className={classnames('fas mr-1 fa-plus')} />
              MFクラウドから事業所(親会社)を追加
            </ProgressButton>
          </div>
          <div className="mt-1 d-flex justify-content-end gap-1">
            <AddButton
              color="secondary"
              outline
              itemRef={companiesRef.doc()}
              initialValues={{ fiscalYears: [{}, {}] }}
              processValues={(values) => ({
                ...values,
                fiscalYears: values.fiscalYears.map(_ => ({ ..._, start_date: formatDate(startOfMonth(_.start_date), 'yyyy-MM-dd'), end_date: formatDate(endOfMonth(_.end_date), 'yyyy-MM-dd'), })),
                users: { [user.id]: { role: 'owner', }, },
                externalType: null,
              })}
              FormModal={NonFreeeCompanyFormModal}
              label="その他の事業所(親会社)を追加"
            />
          </div>
          <div className="mt-4">
            {
              (companies || []).length > 0 ? (
                <div className="d-flex flex-column gap-2">
                  {
                    companies.filter(_ => _.display_name).map((company) => {
                      const { display_name: name, id } = company;
                      const subsidiaries = subsidiariesGroupedByCompanyId[id] || [];

                      return (
                        <div key={id} className="card position-relative">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <Link className="h2" to={`/companies/${id}`}>{name}</Link>
                                <div className="mt-3 small" style={{ color: '#bbb', }}>事業所ID: {id}</div>
                                <div>
                                  {
                                    subsidiaries.length > 0 ? (
                                      <div className="d-flex gap-2 flex-wrap">
                                        {
                                          subsidiaries.slice(0, displaySubsidiariesLength).map((subsidiary) => {
                                            const { id, display_name } = subsidiary;
                                            return (
                                              <span key={id}>
                                                {display_name}
                                              </span>
                                            );
                                          })
                                        }
                                        {
                                          subsidiaries.length > displaySubsidiariesLength && (
                                            <div>他{subsidiaries.length - displaySubsidiariesLength}社</div>
                                          )
                                        }
                                      </div>
                                    ) : (
                                      <div className="text-muted">連結子会社なし</div>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              ) : (
                <div>事業所はまだありません。</div>
              )
            }
          </div>
        </div>
      </div>
      {
        bugyoCompanies && (
          <NonFreeeCompanyFormModal
            isOpen
            onSubmit={async (values) => {
              const bugyoCompany = bugyoCompanies.find(_ => _.id === values.sourceId);
              await companiesRef.doc().set({
                ...values,
                ...omit(bugyoCompany, ['id']),
                display_name: bugyoCompany.name,
                createdAt: new Date(),
                users: {
                  [user.id]: { role: 'owner', },
                },
                externalType: 'bugyo',
                fiscalYears: values.fiscalYears.map(_ => ({ ..._, start_date: formatDate(startOfMonth(_.start_date), 'yyyy-MM-dd'), end_date: formatDate(endOfMonth(_.end_date), 'yyyy-MM-dd'), })),
                bugyo: {
                  ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
                },
              });
              toast.success('追加しました');
              history.replace('/');
              setBugyoCompanies(null);
            }}
            fields={fields({ externalCompanies: bugyoCompanies.filter(_ => _.existsInYui !== 'true'), })}
            onClickClose={_ => {
              history.replace('/');
              setBugyoCompanies(null);
            }}
          />
        )
      }
      {
        pcaDataAreas && (
          <ModelFormModal
            title="事業所 追加"
            isOpen
            onSubmit={async (values) => {
              const pcaDataAreasByName = keyBy(pcaDataAreas, 'Name');
              await companiesRef.doc().set({
                display_name: values.display_name,
                createdAt: new Date(),
                users: {
                  [user.id]: { role: 'owner', },
                },
                externalType: 'pca',
                fiscalYears: values.dataAreaIds.map(_ => pcaDataAreasByName[_]).map(_ => ({ ..._, start_date: [_.CompanyTermStart.slice(0, 4), _.CompanyTermStart.slice(4, 6), _.CompanyTermStart.slice(6)].join('-'), end_date: [_.CompanyTermEnd.slice(0, 4), _.CompanyTermEnd.slice(4, 6), _.CompanyTermEnd.slice(6)].join('-'), })),
                pca: {
                  ...pick(queryParams, ['accessToken', 'refreshToken', 'expires']),
                  dataCenterCode: pcaDataCenterCode,
                  isHyper: !!pcaIsHyper,
                },
              });
              toast.success('追加しました');
              history.replace('/');
              setPcaDataAreas(null);
              setPcaIntegrationPath('');
              setPcaDataCenterCode('');
              setPcaIsHyper(false);
            }}
            fields={pcaCompanyFields({ dataAreas: pcaDataAreas, })}
            onClickClose={_ => {
              history.replace('/');
              setPcaDataAreas(null);
            }}
          />
        )
      }
    </div>
  );
};
