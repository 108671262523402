const { uniqBy, range, inRange, get, isEmpty, keyBy, } = require('lodash');
const { format: formatDate} = require('date-fns');

const { optionLabel, } = require('../lib/consolidationAccountItems');

const batchCloneFields = ({ company, periodOptions, fiscalYearOfPeriod, }) => {
  return {
    period: {
      label: '事業年度',
      type: 'select',
      options: periodOptions,
      validations: {
        required: v => v != null,
      },
    },
    yearMonth: {
      label: '月度',
      type: 'select',
      options: (state) => {
        const fiscalYear = fiscalYearOfPeriod(state.period, company.fiscalYears) || {};
        const { start_date: startDate, end_date: endDate } = fiscalYear;
        const monthOptions = startDate ? range(formatDate(new Date(startDate), 'yyyyMM'), formatDate(new Date(endDate), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
        return monthOptions;
      },
      validations: {
        required: v => v != null,
      },
    },
  };
};

const fields = ({ companies = [], accountItems = [], sections = [], otherItems, } = {}) => {
  const companiesById = keyBy(companies, 'id');
  const unique = (v, s) => otherItems?.every(o => ['opponentCompanyId', 'accountItemId', 'sectionId'].some(_ => o[_] !== s[_]));
  return {
    opponentCompanyId: {
      label: '相手会社',
      type: 'select',
      options: companies.map(_ => ({ label: _.display_name, value: _.id })),
      validations: {
        unique,
      },
    },
    accountItemId: {
      label: '個別勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: optionLabel(_), value: _.id })),
      validations: {
        required: v => v != null,
        unique,
      },
    },
    sectionId: {
      label: '部門',
      type: 'select',
      options: sections.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        unique,
      },
    },
    amount: {
      label: '金額',
      type: 'float',
      validations: {
        required: v => v != null,
      },
    },
    remoteAmount: {
      label: '現地通貨金額',
      type: 'float',
      hidden: _ => get(companiesById, [_.opponentCompanyId, 'currency'], 'jpy') === 'jpy',
    },
    note: {
      label: '備考',
      type: 'text',
    }
  };
};

const getExtraPartnerValues = (relatedCompany, targetCompanyId) => {
  const targetCompanyValues = relatedCompany.relatedCompanyPartnerSettings?.[targetCompanyId];
  const oldExternalPartnerId = targetCompanyValues?.[relatedCompany.externalType === 'freee' ? 'freeePartnerId' : 'externalPartnerId'];
  const oldExternalPartnerValues = targetCompanyValues?.[relatedCompany.externalType === 'freee' ? 'freeePartner' : 'externalPartner'];
  return {
    ...(oldExternalPartnerId && { [oldExternalPartnerId]: { name: oldExternalPartnerValues?.name } }),
    ...targetCompanyValues?.externalPartnerValues,
  };
};

exports.fields = fields;
exports.batchCloneFields = batchCloneFields;
exports.getExtraPartnerValues = getExtraPartnerValues;
