import React, { useMemo, } from 'react';
import { endOfMonth, parse as parseDate, format as formatDate } from 'date-fns';

import { operationTypes } from '../shared/config';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useCompany from './hooks/useCompany';
import useQueryParams from './hooks/useQueryParams';
import ModalButton from './ModalButton';

export default function OperationHistoryButton (props) {
  const { type, size = 'sm', color = 'link', className = 'p-0 text-muted', } = props;
  const { period, yearMonth, } = useQueryParams();
  const company = useCompany();
  const collectionRef = useMemo(_ => company?.ref.collection('logs').where('operationType', '==', type).where('payload.yearMonth', '==', yearMonth).orderBy('createdAt', 'desc'), [company != null, type, yearMonth]);
  const logs = useCollectionSubscription(collectionRef.limit(1), [collectionRef]);

  return logs?.length > 0 && (
    <ModalButton {...{ size, color, className, }} title="履歴" content={_ => <OperationHistory type={type} collectionRef={collectionRef} />}>
      <span className="fas fa-history" />
    </ModalButton>
  );
};

function OperationHistory (props) {
  const { collectionRef } = props;
  const company = useCompany();
  const logs = useCollectionSubscription(collectionRef.limit(100), [collectionRef]);
  return (
    <div className="d-flex flex-column gap-3">
      {
        logs.map((log) => {
          const { id, operationType, createdAt, createdBy, payload, } = log;
          return (
            <div key={id}>
              <div className="d-flex gap-1 text-muted small">
                <div>
                  {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                </div>
                <div>
                  {createdBy.displayName}
                </div>
              </div>
              <div>
                {operationTypes[operationType]?.label} ({payload.yearMonth.slice(0, 4)}/{payload.yearMonth.slice(4)})
              </div>
            </div>
          );
        })
      }
    </div>
  );
}
