const { isEmpty, zip, keyBy, sortBy, groupBy, } = require('lodash');
const numeral = require('numeral');

const { integerFormat, floatFormat } = require('../util');
const { getCategory, } = require('../models/accountItem');
const { accountItemCategories, accountItemCategoryNames } = require('../config');

const { keys } = Object;
const { abs } = Math;
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');

const filterRetainedEarningConsolidationJournals = (allConsolidationAccountItemsById, consolidationJournals) => {
  return consolidationJournals
    .filter(_ => _.isOpening)
    .map(_ => ({
      ..._,
      items: _.items.map((item) => {
        const shouldExclude = (itemId) => {
          const category = getCategory(allConsolidationAccountItemsById[itemId]);
          return ['pl', 'cr'].includes(category?.type);
        };
        return {
          ...item,
          debitAmount: shouldExclude(item.debitItemId) ? 0 : item.debitAmount,
          creditAmount: shouldExclude(item.creditItemId) ? 0 : item.creditAmount,
        };
      }),
    }));
};

const generateRowGroups = (_items, prevMonthItems = [], retainedEarningItems = [], showsAll = false) => {
  const _categoryItemsByCategory = keyBy(_items.filter(_ => _.itemType === 'category'), 'accountItemCategory.name');
  const prevMonthItemsByKey = keyBy(prevMonthItems, 'key');
  const items = _items.map((item) => {
    const ratio = item.accountItemCategory.ratio(item, _categoryItemsByCategory);
    const prevMonthItem = prevMonthItemsByKey[item.key];
    const prevMonthChange = item.conclusiveAmount - (prevMonthItem?.conclusiveAmount || 0);
    return {
      ...item,
      ratio,
      prevMonthChange,
    };
  });
  const retainedEarningCategoryItems = retainedEarningItems.filter(_ => _.itemType === 'category');
  const retainedEarningItemsGroupedByCategory = groupBy(retainedEarningItems, 'accountItemCategory.name');
  const retainedEarningCategoryItemsByCategory = keyBy(retainedEarningCategoryItems, 'accountItemCategory.name');
  const categoryItems = items.filter(_ => _.itemType === 'category');
  const itemsGroupedByCategory = groupBy(items, 'accountItemCategory.name');
  const categoryItemsByCategory = keyBy(categoryItems, 'accountItemCategory.name');
  const rowGroups = ['bs', 'pl', 'cr'].map((type) => {
    const accountItemCategories = accountItemCategoriesGroupedByType[type];
    return {
      type,
      rowGroups: accountItemCategories.map((category) => {
        const directItems = (itemsGroupedByCategory[category.name] || [])
          .filter(_ => _.itemType === 'consolidationAccountItem')
          .filter(_ => showsAll || [..._.consolidationColumns.map(_ => _.amount), ..._.companyColumns.map(_ => _.exchangedAmount)].some(_ => abs(_) > 0));
        const categoryItem = categoryItemsByCategory[category.name];
        return {
          category,
          rows: [...directItems, categoryItem].filter(_ => _),
        };
      }),
    };
  });
  const retainedEarningRowGroups = accountItemCategoriesByName['利益剰余金'].descendantsAndSelf().map((category) => {
    const directItems = zip(...[(itemsGroupedByCategory[category.name] || []), retainedEarningItemsGroupedByCategory[category.name] || []].map((items) => {
      return items.filter(_ => _.itemType === 'consolidationAccountItem')
    })).filter(_ => _.every(_ => _));
    const categoryItem = [categoryItemsByCategory[category.name], retainedEarningCategoryItemsByCategory[category.name]];
    return {
      category,
      rows: [...directItems, categoryItem.every(_ => _) ? categoryItem : null].filter(_ => _),
    };
  });
  return [rowGroups, retainedEarningRowGroups];
};

const rowsForExport = (company, items, prevMonthItems, retainedEarningItems, displayConsolidationJournalTypes, consolidationJournalTypesById, showsAll = false) => () => {
  console.log(showsAll);
  const [rowGroups, retainedEarningRowGroups] = generateRowGroups(items, prevMonthItems, retainedEarningItems, showsAll);
  return [
    ...rowGroups.flatMap(_ => _.rowGroups.flatMap(_ => _.rows)).map(({ code, item, itemType, companyColumns, simpleSum, consolidationColumns, conclusiveAmount, ratio, prevMonthChange, }) => {
      return {
        category: itemType === 'category' ? item.name : null,
        itemName: itemType === 'consolidationAccountItem' ? item.name : null,
        accountCode: company[`integration_account_id_${item?.id}`] || code,
        ...(
          companyColumns.reduce((x, companyColumn) => {
            const { company, exchangedAmount } = companyColumn;
            return {
              ...x,
              [company.display_name]: exchangedAmount,
            };
          }, {})
        ),
        simpleSum,
        ...(
          consolidationColumns.filter(_ => displayConsolidationJournalTypes.includes(_.type)).reduce((x, consolidationColumn) => {
            const { type, amount } = consolidationColumn;
            return {
              ...x,
              [consolidationJournalTypesById[type].name]: amount,
            };
          }, {})
        ),
        conclusiveAmount,
        ratio: isFinite(ratio) ? ratio : '',
        itemDisclosureName: item.group_name,
        prevMonthChange,
      };
    }),
    ...retainedEarningRowGroups.flatMap(_ => _.rows).flatMap(([item, prevYearItem]) => {
      const { item: { name, group_name, }, itemType, consolidationColumns, conclusiveAmount, ratio, } = item;
      return [_ => _.prevYearAmount, _ => _.amount - _.prevYearAmount, _ => _.amount].map((f) => {
        return {
          category: itemType === 'category' ? name : null,
          itemName: itemType === 'consolidationAccountItem' ? name : null,
          ...(
            item.companyColumns.reduce((x, companyColumn, i) => {
              const prevYearCompanyColumn = prevYearItem.companyColumns[i];
              const { company, exchangedAmount } = companyColumn;
              return {
                ...x,
                [company.display_name]: f({ amount: exchangedAmount, prevYearAmount: prevYearCompanyColumn.exchangedAmount }),
              };
            }, {})
          ),
          simpleSum: f({ amount: item.simpleSum, prevYearAmount: prevYearItem.simpleSum }),
          ...(
            consolidationColumns.filter(_ => displayConsolidationJournalTypes.includes(_.type)).reduce((x, consolidationColumn, i) => {
              const { type, amount } = consolidationColumn;
              const prevYearConsolidationColumn = prevYearItem.consolidationColumns.filter(_ => displayConsolidationJournalTypes.includes(_.type))[i];
              return {
                ...x,
                [consolidationJournalTypesById[type].name]: f({ amount, prevYearAmount: prevYearConsolidationColumn.amount, }),
              };
            }, {})
          ),
          conclusiveAmount: f({ amount: conclusiveAmount, prevYearAmount: prevYearItem.conclusiveAmount }),
        };
      });
    }),
  ];
};

const computeAlerts = (items, retainedEarningItems, prevYearItems, company) => {
  if(isEmpty(items)) return [];

  const companies = items[0].companyColumns.map(_ => _.company);
  return [
    ...companies.map((company, i) => {
      const debitAmount = items.find(_ => _.item.name === '資産').companyColumns[i].exchangedAmount;
      const creditAmount  = items.find(_ => _.item.name === '負債及び純資産').companyColumns[i].exchangedAmount;
      const diff = debitAmount - creditAmount;
      return diff !== 0 && `[${company.display_name}] 貸借不一致です (${integerFormat(Math.abs(diff))})`;
    }),
    (() => {
      const diff = [retainedEarningItems, prevYearItems].map(_ => _.find(_ => _.itemType === 'category' && _.key === '利益剰余金')?.conclusiveAmount).reduce((x, y) => x - y);
      return diff !== 0 && `期首と前期末の利益剰余金に差額があります (${integerFormat(Math.abs(diff))})`;
    })(),
    (() => {
      const capitalItem = items.find(_ => _.itemType === 'category' && _.item.name === '資本金');
      const diff = capitalItem.conclusiveAmount - capitalItem.companyColumns.find(_ => _.company.id === company.id)?.exchangedAmount;
      return diff !== 0 && `資本金残高が親会社の資本金残高と不一致です (${numeral(diff).format()})`;
    })(),
  ].filter(_ => _);
};

exports.filterRetainedEarningConsolidationJournals = filterRetainedEarningConsolidationJournals;
exports.generateRowGroups = generateRowGroups;
exports.computeAlerts = computeAlerts;
exports.rowsForExport = rowsForExport;
