import React, { Component } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { format as formatDate, startOfMonth, endOfMonth, } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { numberFormat } from '../../util';
import { startOfMonthByFiscalYears, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { periodOfFiscalYear, } from '../../shared/util';
import { currencies, externalTypes, } from '../../shared/config';
import { canUpdateCompany } from '../../shared/abilities';
import { fieldsToEdit } from '../../shared/models/subsidiary';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import NonFreeeCompanyFormModal from '../modals/NonFreeeCompanyFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';

export default RelatedCompanyPage(function CompanyRelatedCompany (props) {
  const { relatedCompany, isParent, company, user, subsidiaryId, period, yearMonth, } = props;

  return props.translate(
    <div className="company-related-company">
      <div className="mb-3 d-flex justify-content-end gap-1">
        <EditButton
          itemRef={relatedCompany.ref}
          processValues={_ => ({
            ..._,
            ...(_.fiscalYears && { fiscalYears: _.fiscalYears.map(_ => ({ ..._, start_date: formatDate(startOfMonth(_.start_date), 'yyyy-MM-dd'), end_date: formatDate(endOfMonth(_.end_date), 'yyyy-MM-dd'), })), }),
          })}
          FormModal={(relatedCompany.externalType == null || relatedCompany.externalType === 'bugyo') ? NonFreeeCompanyFormModal : ModelFormModal}
          formProps={{ title: '事業所 編集', fields: fieldsToEdit({ isParent, company, relatedCompany, }), }}
          disabled={!canUpdateCompany(company, user)}
        />
        {!isParent && <DeleteButton itemRef={relatedCompany.ref} disabled={!canUpdateCompany(company, user)} />}
      </div>
      <div>
        <table className="table table-bordered">
          <tbody className="thead-light">
            <tr>
              <th style={{ width: 200 }}>事業所名</th>
              <td>
                {relatedCompany.display_name}
                {relatedCompany.externalType != null && <span className="badge badge-info ml-1">{externalTypes[relatedCompany.externalType]?.label}連携</span>}
              </td>
            </tr>
            <tr>
              <th style={{ width: 200 }}>通貨</th>
              <td>
                {currencies[relatedCompany.currency || 'jpy'].label}
              </td>
            </tr>
            {
              relatedCompany.joinPeriod != null && (
                <tr>
                  <th style={{ width: 200 }}>連結対象になった年月</th>
                  <td>
                    {relatedCompany.joinYearMonth}
                    {relatedCompany.isStartJoin && <span className="ml-1 badge badge-info">月初</span>}
                  </td>
                </tr>
              )
            }
            {
              relatedCompany.exceptPeriod != null && (
                <tr>
                  <th style={{ width: 200 }}>連結除外になった年月</th>
                  <td>
                    {relatedCompany.exceptYearMonth}
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
